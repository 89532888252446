<template>
	<div class="app-box">
		<div class="title-box">
			<div class="line"></div>
			<p class="title">回收站</p>
		</div>
		<a-divider style="margin-left: -10px" />
		<a-form-model class="form-search" :colon="false" layout="inline" :model="query" labelAlign="left"
			@submit="handleSearch" @submit.native.prevent>
			<a-row>
				<a-form-model-item style="float: right; margin-right: 0">
					<a-button type="primary" html-type="submit"> 查询 </a-button>
					<a-button @click="Reset">重置</a-button>
				</a-form-model-item>
				<a-form-model-item label="设备编号">
					<a-input v-model="query.equipment_number" placeholder="设备编号" />
				</a-form-model-item>
				<a-form-model-item label="设备类型">
					<a-select placeholder="请选择" v-model="query.connect_type">
						<a-select-option :value="0" :key="0">
							全部
						</a-select-option>
						<a-select-option :value="2" :key="2">
							4G设备
						</a-select-option>
						<a-select-option :value="1" :key="1">
							WiFi设备
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="硬件编号">
					<a-input v-model="query.hardware_number" placeholder="硬件编号" />
				</a-form-model-item>
			</a-row>
		</a-form-model>
		<a-table class="main-table" :pagination="{
			current: query.page,
			total: pagetotal,
			showTotal: (pagetotal, range) => `共 ${pagetotal} 条数据`,
		}" @change="listChange" :columns="columns" :data-source="data" rowKey="equipment_id" style="margin-top: 14px"
			v-viewer>
			<span slot="all_rent_count" slot-scope="text, record">
				<a-space>
					<a @click="toOrder(record)">{{ record.all_rent_count }}次</a>
				</a-space>
			</span>
			<span slot="equipment_status" slot-scope="text, record">
				<a-tag :color="record.equipment_status === 1 ? '#74CE78' : '#757776'">
					{{ record.equipment_status === 1 ? '在线' : '离线' }}
				</a-tag>
			</span>
			<span slot="type" slot-scope="text, record">
				{{ getName(classListNoPage, record.type, "class_id", "name") }}
			</span>
			<span slot="action" slot-scope="text, record">
				<a-space>
					<a-popconfirm title="是否恢复？" ok-text="是" cancel-text="否" @confirm="recovery(record.equipment_id)">
						<a style="color: #E0352B">恢复</a>
					</a-popconfirm>
				</a-space>
			</span>
		</a-table>
	</div>
</template>

<script>
import { domain } from '@/siteInfo.js'
export default {
	data() {
		return {
			query: {
				page: 1,
				limit: 10
			},
			hospitals: [],
			classList: [],
			departments: [],
			visible2: false,
			pagetotal: 0,
			ks_visible: false,
			bindObj: {},
			visible: false,
			equipment: null,
			classListNoPage: [],
			classForm: {
				"name": ""
			},
			columns: [
				{
					title: '序号',
					customRender: (text, record, index) => index + 1
				},
				{
					title: '设备编号',
					dataIndex: 'equipment_number'
				},
				{
					title: '类型',
					scopedSlots: {
						customRender: 'type'
					}
				},
				{
					title: '硬件编号',
					dataIndex: 'hardware_number'
				},
				{
					title: '累计租用次数',
					scopedSlots: {
						customRender: 'all_rent_count'
					}
				},
				{
					title: '操作',
					scopedSlots: {
						customRender: 'action'
					}
				}
			],
			classColumns: [
				{
					title: '名称',
					dataIndex: 'name'
				},
				{
					title: '操作',
					scopedSlots: {
						customRender: 'action'
					}
				}
			],
			rules2: {
				name: [
					{ required: true, message: '请输入名称', trigger: 'blur' },
				],
			},
			data: [],
			selectedRowKeys: [],

			bindRules: {
				department_id: [
					{
						required: true,
						message: '请选择科室',
						trigger: 'blur'
					}
				]
			}
		}
	},
	created() {
		this.getList()
		this.getEquipmentClass()
	},
	methods: {
		recovery(id) {
			this.$post('equipment/recovery', {
				"equipment_id": id
			})
				.then(res => {
					let { code, msg, data } = res
					if (code == 0) {
						this.getList()
						this.$message.success(msg, 1.5)
					} else {
						this.$message.error(msg, 1.5)
					}
				})
		},
		toEditClass(record) {
			this.classForm.name = record.name
			this.classForm.class_id = record.class_id
			this.visible3 = true
		},
		submitClass() {
			this.$refs.classForm.validate(valid => {
				if (valid) {
					this.$post('equipmentClass/edit', this.classForm)
						.then(res => {
							let { code, msg, data } = res
							if (code == 0) {
								this.getClassList()
								this.getEquipmentClass()
								this.onClose3()
								this.$message.success(msg, 4)
							} else {
								this.$message.error(msg, 1.5)
							}
						})
						.catch(err => {
							this.confirmLoading = false
						})
				}
			})
		},
		getClassList() {
			this.$post(domain + '/admin/v1/equipmentClass/list', this.classQuery).then(res => {
				if (res.code === 0) {
					this.classList = res.data.list
					this.visible2 = true
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		getName(arr, id, idName, lableName) { //通过id获取数组名称
			if (id || id == '0') {
				let searId = id.toString()
				const index = arr.findIndex((role) => role[idName] === searId)
				return index >= 0 ? arr[index][lableName] : undefined
			} else {
				return undefined
			}
		},
		getEquipmentClass() {
			this.$post('equipmentClass/listNoPage', {})
				.then(res => {
					let { code, msg, data } = res
					if (code == 0) {
						this.classListNoPage = data
						console.log("data", this.classListNoPage)
					} else {
						this.$message.error(msg, 1.5)
					}
				})
		},
		onClose2() {
			this.visible2 = false
		},
		onClose3() {
			this.visible3 = false
			this.classForm = {
				"name": ""
			}
		},
		classListChange(e) {
			this.classQuery.page = e.current
			this.getClassList()
		},
		handleCancel() {
			this.visible = false
			this.initForm()
		},
		beforeUpload(file) {
			this.file = file
			this.uping = true
			this.spinning = true
			console.log('before', file)
		},
		handleChange(e) {
			console.log('改变。。。')
			if (e.file.status == 'done') {
				console.log(e.file.response)
				this.spinning = false
				this.uping = false
				let { msg, data, code } = e.file.response
				if (code == 0) {
					this.$message.success(msg, 1.5)
					this.getList()
				} else {
					this.$message.warn(msg, 1.5)
				}
				console.log(data)
			}
		},

		Reset() {
			this.query = {
				page: 1,
				limit: this.query.limit
			}
			this.getList()
		},
		getList() {
			this.$post(domain + '/admin/v1/equipment/recoveryList', this.query).then(res => {
				if (res.code === 0) {
					this.data = res.data.data
					this.pagetotal = res.data.total
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		listChange(e) {
			this.query.page = e.current
			this.getList()
		},
		handleSearch(query) {
			this.query.page = 1
			this.customer_id = null
			this.getList()
			console.log(query)
		},

	},
	computed: {
		token() {
			return this.$store.state.token
		},
	},
	mounted() {
		this.timer = setInterval(() => {
			for (let i = 0; i < this.data.length; i++) {
				this.$set(this.tempSearchTimes, this.data[i].equipment_id, this.searchTime(this.data[i].equipment_id));
				console.log(this.tempSearchTimes)
			}
		}, 1000);
	},
	beforeDestroy() {
		clearInterval(this.timer); // 清除定时器
	},
}
</script>

<style lang="less" scoped>
.title-box {
	display: flex;
	align-items: center;
	margin-left: 8px;

	.line {
		width: 5px;
		height: 14px;
		background-color: #4e80f8;
	}

	.title {
		color: #383f50;
		font-size: 16px;
		margin-left: 5px;
	}
}

.img-qr {
	height: 30px;
	cursor: pointer;
}
</style>

<style lang="less">
.form-search {
	.ant-form-item-label {
		width: 70px;
	}
}
</style>
